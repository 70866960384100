@import "../../styles/abstracts/variables";

.saved-credit-card {
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  border-top: 1px solid $tt-color-text-dark;
  border-bottom: 1px solid $tt-color-text-dark;
  opacity: 0.5;
}
