
@import "../../styles/abstracts/variables";

.cropper-modal{
  background-color: #27272726;
}

.cropper-point {
  border-radius: 50%;
  opacity: 1;
  background-color: $tt-color-success;
  transform: scale(2.5);

  &.point-se {
    width: 10px;
    height: 10px;
    background-image: url("/app/images/text-resize.png");
    background-size: cover;
  }
}

@media (min-width: 768px) {
  .cropper-point.point-se {
    width: 15px;
    height: 15px;
  }
}

.cropper-line {
  background-color: $tt-color-success;
}

.cropper-view-box {
  outline: 2px solid $tt-color-success;
  outline-color: rgba(73, 189, 161, 0.52);
}

.cropper-center:after,
.cropper-center:before {
  transform: scale(2);
}

.cropper-dashed {
  opacity: 1;
  border: 0 solid $tt-color-success;

  &.dashed-h {
    border-top-width: 1px;
    border-bottom-width: 1px;
  }

  &.dashed-v {
    border-right-width: 1px;
    border-left-width: 1px;
  }
}
