@import "../../styles/abstracts/variables";

.photo-magazine-preview__render {
  position: relative;

  .shadow {
    -webkit-transition: -webkit-box-shadow 0.5s;
    -moz-transition: -moz-box-shadow 0.5s;
    -o-transition: -webkit-box-shadow 0.5s;
    -ms-transition: -ms-box-shadow 0.5s;

    -webkit-box-shadow: 0 0 20px #ccc;
    -moz-box-shadow: 0 0 20px #ccc;
    -o-box-shadow: 0 0 20px #ccc;
    -ms-box-shadow: 0 0 20px #ccc;
    box-shadow: 0 0 20px #ccc;
  }

  .page {
    position: relative;
    background-size: cover;
    background-position: center center;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }

  .page--blank {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: #fff;
  }

  .page__blank-label {
    color: rgba(#000, 0.6);
    font-size: 0.7rem;
  }

  .page__number {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    color: #fff;
    background: rgba(#000, 0.8);
    padding: 1rem;
  }
}

.photo-magazine-preview__navigation {
  margin-top: $tt-spacing-unit-default;
}
