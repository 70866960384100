@import "../../styles/abstracts/variables";

.cookie-warning {
  padding: $tt-spacing-unit-default;
  background-color: rgba(85, 85, 85, 0.82);
  border-bottom: 1px solid darken($tt-color-border-light, 15%);
  font-size: $tt-font-size-small;
  line-height: 1.4;
  position: fixed;
  z-index: 500;
  bottom: 0;
  width: 100%;
  p {
    color: white;
    font-weight: 400;
    a {
      color: white;
    }
  }
}
