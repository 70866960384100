/* entire container, keeps perspective */
.flip-container {
  perspective: 1000px;
  transition: transform 0.2s;
  transform-origin: top center;
  margin: 0 auto;
}

.flip-container--flipped .flipper {
  transform: rotateY(180deg);
}

.flip-container--flipped .front {
  pointer-events: none;
  opacity: 0;
}

.flip-container--flipped .back {
  pointer-events: all;
  opacity: 1;
  transition: opacity 0.4s 0.1s;
  backface-visibility: visible;
}

.flip-container,
.front,
.back {
  width: 100%;
}

/* flip speed goes here */
.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

/* hide back of pane during swap */
.front,
.back {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.4s 0.1s;
}

/* front pane, placed above back */
.front {
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
  transform: rotateY(180deg);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.6s;
  backface-visibility: hidden;
}
