@import "../../styles/abstracts/variables";

.card-opener-container {
  width: 200px;
  height: 260px;
  position: relative;
  perspective: 1200px;
  margin: 0 auto;
  transition: all 0.15s ease;
  transform-style: preserve-3d;
  transform-origin: center center;
  transition-delay: 0s;
}

.card-opener {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: transform 1s;
  transform-style: preserve-3d;
  transform-origin: left center;
  transition-delay: 0.2s;
}

.card-opener--is-open {
  transform: translateY(0) rotateY(-120deg);
  transition-delay: 0s;
}

.card-opener-container--is-open {
  .card-opener__inside {
    opacity: 1;
    pointer-events: all;
    transition-delay: 0.2s;
  }
}

.card-opener__inside {
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease-in-out;
  transition-delay: 0s;
}

.card-opener figure {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  face-visibility: hidden;
  backface-visibility: hidden;
}

.card-opener .card-opener__front {
  margin: 0;
  z-index: -1;
}

.card-opener .card-opener__back {
  background: #f3f3f3;
  transform: rotateY(180deg);
  margin: 0;
  border: 1px solid #eee;
}
