body {
  font-family: $tt-font-stack-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-weight: $tt-font-weight-light;
  margin-bottom: $tt-spacing-unit-default;
  line-height: 1.5em;
  color: rgba($tt-color-text-dark, 0.8);

  &:last-child {
    margin-bottom: 0;
  }
}

ul {
  font-weight: $tt-font-weight-light;
  margin-bottom: $tt-spacing-unit-default;
  line-height: 1.5em;
  color: rgba($tt-color-text-dark, 0.8);
  margin-left: 35px; // check this out
}

dd {
  margin-left: 10px;
}

.help-text {
  opacity: 0.7;
  font-size: $tt-font-size-small;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-primary {
  color: $tt-color-primary;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
