@import "../../styles/abstracts/variables";

.photo-prints-prebag__item {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  padding: 0 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  .quantity-picker {
    margin-left: auto;
  }

  .html-renderer-container {
    margin: 0;
    margin-right: 2rem;
  }

  .photo-prints-prebag__item-preview{
    &.item-preview--is-glossy-or-matt{
      overflow: hidden;
      position: relative;
      .glossy-or-matt {
        width: 55px;
        height: 150px;
        position: absolute;
        top: -20px;
        left: -15vh;
        transition: 1s;
        transform: rotate(30deg);
        background: linear-gradient(90deg, rgba(255,255,255, 0)50%, rgba(255,255,255,0.7)100%);
      }
    }

    &.item-preview--is-glossy {
      .glossy-or-matt {
        transform: rotate(0);
        left: 25vw;
        top: 0px;
        transition: 1s ease;
        height: 150px;
      }
    }
  }

}

.photo-prints-prebag__pricing-indicator {
  display: flex;
  align-items: center;
  margin: 0 -$tt-spacing-unit-small;
  margin-bottom: $tt-spacing-unit-small;
  padding: $tt-spacing-unit-small;
  background-color: #043253;

  p {
    flex: 1;
    padding-right: $tt-spacing-unit-default;
    margin-bottom: 0;
    color: #fff;
    font-size: $tt-font-size-small;
  }

  .btn {
    background-color: #fff;
    color: #043253;
  }

  .btn__label {
    font-size: $tt-font-size-small;
  }
}
