@import "../../styles/abstracts/variables";

$editor-fill-option-dimensions: 32px;
$checkmark-image-width: 22px;
$checkmark-image-height: 18px;

.editor__fill-option {
  width: $editor-fill-option-dimensions;
  height: $editor-fill-option-dimensions;
  display: inline-block;
  margin-right: $tt-spacing-unit-default;
  border-radius: 50%;
  background-size: cover;
  background-color: #fff;
  border: 2px solid #eee;
  position: relative;
  transition: border-color 0.2s;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    border-color: #fff;
  }
  &.fixed-fill-option {
    position: absolute;
    z-index: 1;
    background: #f9f9f9;
    height: 35px;
    border-radius: 0px;
    width: 70px;
    left: -13px;
    padding-left: 28px;
    border: none;
    padding-top: 1px;
  }
}

.editor__fill-option--active {
  border-color: #fff;

  .editor__fill-option__checkmark {
    transform: scale(1);
  }
}

.editor__fill-option__checkmark {
  width: $checkmark-image-width;
  height: $checkmark-image-height;
  position: absolute;
  top: calc(50% - #{$checkmark-image-height / 2});
  left: calc(50% - #{$checkmark-image-width / 2});
  transform: scale(0);
  transition: transform 0.2s ease-in-out;
}
