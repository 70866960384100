.paypal-button-size-responsive > .xcomponent-outlet {
  max-width: 100% !important;
}

.paypal-button-container {
  max-width: 100% !important;
}

.paypal-button {
  max-width: 100% !important;
}

.paypal-wait {
  margin-top: 10px;
  text-align: center;
}
