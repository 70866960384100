@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins/icon-color";

$button-height-default: 44px;
$button-height-small: 32px;

.btn {
  display: inline-block;
  min-height: $button-height-default;
  max-height: $button-height-default;
  line-height: $button-height-default - 2;
  padding: 0 calc(#{$tt-spacing-unit-default} - 1px);
  margin-right: $tt-spacing-unit-small;
  margin-bottom: $tt-spacing-unit-default * 1.25;
  border: 1px solid transparent;
  outline: 0;
  border-radius: 2px;
  font-family: $tt-font-stack-primary;
  font-size: $tt-font-size-regular;
  font-weight: $tt-font-weight-medium;
  text-decoration: none;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s, opacity 0.2s;

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

  &[disabled] {
    opacity: 0.7;
  }
}

.btn--circle {
  border-radius: 50%;
}

.btn:not(.btn--only-icon) {
  &.btn--icon-position-left {
    .btn__icon {
      margin-right: $tt-spacing-unit-small;
    }
  }

  &.btn--icon-position-right {
    .btn__icon {
      margin-left: $tt-spacing-unit-small;
    }
  }
}

.btn--size-small {
  min-height: $button-height-small;
  max-height: $button-height-small;
  line-height: $button-height-small - 2;
  padding: 0 $tt-spacing-unit-small;
}

.btn__icon,
.btn__label {
  display: inline-block;
  height: $button-height-default;
}

.btn__icon {
  .icon {
    position: relative;
    top: 7px;
  }
}

.btn--block {
  display: block;
  width: 100%;
  text-align: center;
}

.btn--only-icon:not(.btn--block) {
  width: $button-height-default;
  padding: 0;
}

@mixin buttonThemeVariant($themeName, $primaryColor, $secondaryColor) {
  .btn--#{$themeName} {
    &.btn--primary {
      background-color: $primaryColor;
      border-color: $primaryColor;
      color: $secondaryColor;
      @include iconColor($secondaryColor);

      &:active {
        background-color: lighten($primaryColor, 5%);
        border-color: lighten($primaryColor, 5%);
        @include iconColor($secondaryColor);
      }
    }

    &.btn--secondary {
      background-color: $secondaryColor;
      border-color: $primaryColor;
      color: $primaryColor;
      @include iconColor($primaryColor);

      &:active {
        background-color: transparent;
        border-color: lighten($primaryColor, 5%);
        opacity: 0.8;
      }
    }

    &.btn--tertiary {
      background-color: transparent;
      border-color: transparent;
      color: $primaryColor;
      @include iconColor($primaryColor);

      &:active {
        background-color: transparent;
        border-color: transparent;
        opacity: 0.8;
      }
    }
  }
}

@include buttonThemeVariant("default", $tt-color-primary, $tt-color-text-light);
@include buttonThemeVariant("aqua", $tt-color-aqua, $tt-color-text-light);
@include buttonThemeVariant("dark-blue", $tt-color-dark-blue, $tt-color-text-light);
@include buttonThemeVariant("muted", #919191, $tt-color-text-light);
@include buttonThemeVariant("success", $tt-color-success, $tt-color-text-light);

.btn--facebook,
.btn--instagram,
.btn--flickr,
.btn--dropbox,
.btn--google {
  color: $tt-color-text-light;
}

.btn.btn--facebook {
  background-color: $tt-color-facebook;
  border-color: $tt-color-facebook;
  &:hover,
  &:active {
    background-color: lighten($tt-color-facebook, 5%);
    border-color: lighten($tt-color-facebook, 5%);
  }
}

.btn.btn--recent {
  background-color: $tt-color-recent;
  border-color: $tt-color-recent;
  &:hover,
  &:active {
    background-color: lighten($tt-color-recent, 5%);
    border-color: lighten($tt-color-recent, 5%);
  }
}


.btn.btn--instagram {
  background-color: $tt-color-instagram;
  border-color: $tt-color-instagram;
  &:hover,
  &:active {
    background-color: lighten($tt-color-instagram, 5%);
    border-color: lighten($tt-color-instagram, 5%);
  }
}

.btn.btn--google {
  background-color: $tt-color-google;
  border-color: $tt-color-google;
  &:hover,
  &:active {
    background-color: lighten($tt-color-google, 5%);
    border-color: lighten($tt-color-google, 5%);
  }
}

.btn.btn--dropbox{
  background-color: $tt-color-dropbox;
  border-color: $tt-color-dropbox;
  &:hover,
  &:active {
    background-color: lighten($tt-color-dropbox, 5%);
    border-color: lighten($tt-color-dropbox, 5%);
  }
}

.btn.btn--flickr{
  background-color: $tt-color-flickr;
  border-color: $tt-color-flickr;
  &:hover,
  &:active {
    background-color: lighten($tt-color-flickr, 5%);
    border-color: lighten($tt-color-flickr, 5%);
  }
}

.split-button {
  .btn {
    margin-right: 0;
    padding: 0 calc(#{$tt-spacing-unit-default} - 1px);

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
