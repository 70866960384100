@import "../../styles/abstracts/variables";

$tt-basket-badge-dimensions: 18px;
$tt-basket-badge-offset: 0px;

.basket-button {
  position: relative;
  margin-right: $tt-spacing-unit-default;

  .btn {
    margin-right: 0;
  }
}

.basket-button__quantity {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  width: $tt-basket-badge-dimensions;
  height: $tt-basket-badge-dimensions;
  line-height: $tt-basket-badge-dimensions;
  text-align: center;
  border-radius: 50%;
  background-color: $tt-color-primary;
  color: $tt-color-text-light;
  font-size: 0.6rem;
  z-index: 5;
  font-weight: 600;
}
