// Colors
$tt-color-primary: #ff5a5f;
$tt-color-text-light: #fff;
$tt-color-text-dark: #333;
$tt-color-border: #eee;
$tt-color-border-light: #f5f5f5;
$tt-color-facebook: #3b5998;
$tt-color-instagram: #262626;
$tt-color-google: #009688;
$tt-color-dropbox: #007ee5;
$tt-color-flickr: #ff0084;
$tt-color-danger: #ff5a5f;
$tt-color-aqua: #a3d4da;
$tt-color-dark-blue: #043253;
$tt-color-success: #49bda1;
$tt-color-recent: #2bacc1; //#926dea;

// Font families
$tt-font-stack-primary: "Poppins", sans-serif;
$tt-font-stack-secondary: "Poppins", sans-serif;

// Font sizes
$tt-font-size-small: 0.75rem;
$tt-font-size-regular: 0.875rem;
$tt-font-size-large: 1.25rem;
$tt-font-size-extra-large: 1.75rem;

// Font weights
$tt-font-weight-bold: bold;
$tt-font-weight-light: 300;
$tt-font-weight-medium: 500;

// Layout
$tt-spacing-unit-extra-small: 4px;
$tt-spacing-unit-small: 8px;
$tt-spacing-unit-default: 16px;
$tt-spacing-unit-large: 32px;
$tt-max-app-width: 1000px;
$tt-sidebar-width: 250px;

// Media query breakpoints
$mq-breakpoints: (
  mobile: 350px,
  tablet: 740px,
  desktop: 980px,
  wide: 1400px,
);
