.photo-print-preview {
  margin: 0 auto;
  padding: 10px;
  position: relative;
  background-color: #fff;
  transition: background-color 0.2s;

  img {
    display: block;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(#000, 0.3);
    transition: opacity 0.2s;
    opacity: 1;
  }
}

.photo-print-preview--loading {
  background-color: #eee;

  img {
    opacity: 0;
  }

  .photo-print-preview__loading-indicator {
    opacity: 1;
  }
}

.photo-print-preview__loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s;
}
