.grid-row {
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.grid-row--align-vertically {
  align-items: center;
}

.grid-row--align-horizontally {
  justify-content: center;
}

.grid-column {
  flex: 1;
  text-align: left;
}

.grid-column--auto {
  flex: 0 auto;
}

.grid-column--centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
