@import "../../styles/abstracts/variables";

.offline-ui.offline-ui-down.offline-ui-connecting,
.offline-ui.offline-ui-down.offline-ui-waiting {
  background: $tt-color-primary;
  color: #ffffff;
}

.offline-ui.offline-ui-down {
  background: $tt-color-danger;
  color: #ffffff;
  text-align: center;
}

.offline-ui.offline-ui-up {
  background: #29bf9d;
  color: #ffffff;
  text-align: center;
}

.offline-ui.offline-ui-down.offline-ui-connecting:after,
.offline-ui.offline-ui-down.offline-ui-waiting:after {
  border-top-color: #ffffff;
  border-left-color: #ffffff;
}
