@import "../../styles/abstracts/variables";

.editor-canvas-package {
  width: 100%;
  position: relative;
  border-radius: 3px;
}

.editor-canvas-package__background {
  width: 100%;
  display: block;
  box-shadow: 2px 2px 9px 2px #7d6e59;
  border-radius: 3px;
  transition: box-shadow 0.3s ease-in 0.5s;
  transition-delay: 2s;
}

.editor-canvas-package__label {
  position: absolute;
  width: 65%;
  height: 60%;
  left: 18%;
  top: 21%;
  background-color: #fff;
  border-radius: 4px;
  animation-delay: 0.5s;
}

.editor-canvas-package__stamp {
  width: 27%;
  position: absolute;
  right: 7px;
  top: 7px;
  animation-delay: 0.8s;
}

.editor-canvas-package__address {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  padding: 7%;
  display: flex;
  align-items: center;
}
