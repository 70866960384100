@import "../../styles/abstracts/variables";

.footer {
  .btn,
  .grid-row {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.footer--floating {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: calc(#{$tt-max-app-width} - #{$tt-sidebar-width});
}

.footer--padded {
  padding: $tt-spacing-unit-small;
}

.footer--top-shadow {
  box-shadow: 0px -1px 1px 0px #ececec;
  z-index:1;
}

.footer__split-buttons {
  .btn {
    padding: 0;
    margin: 0;

    &:first-child {
      width: 40%;
      margin-left: 8%;
    }

    &:last-child {
      width: 40%;
      margin-left: 5%;
    }
  }
}
