#privy-container #privy-inner-container input {
  text-align: center !important;
}

#privy-container
  #privy-inner-container
  .privy-popup-container
  .privy-powered-by
  .powered-by
  img {
  opacity: 0;
}

#privy-container
  #privy-inner-container
  .privy-popup-container
  .privy-privacy-container {
  opacity: 0;
}
