@import "../../styles/abstracts/variables";

$text-editor-tool-height: 64px;

.editor__tool {
  text-align: center;
  background-color: #f9f9f9;
}

.editor__tool + .editor__tool {
  border-top: 1px solid #eee;
}

.editor__tool--padded {
  padding: $tt-spacing-unit-default;
}

.editor__tool--padded-for-slider {
  padding: $tt-spacing-unit-default 50px; //$tt-spacing-unit-large
}

.editor__help-text {
  padding: 1.5vh;
  text-align: center;
  font-size: $tt-font-size-small;
  opacity: 0.5;
}

.editor__signature-input-modal {
  .modal {
    background-color: #f2f2f2;
  }

  .modal__content {
    padding: $tt-spacing-unit-default;
  }
}

.modal-overlay.editor__text-input-modal {
  z-index: -1;
  pointer-events: none;
}

.modal-overlay.editor__text-input-modal--open {
  z-index: 100;
  pointer-events: all;
}

.editor__text-input-modal {
  .modal {
    background-color: #f2f2f2;
  }
}

.editor__extra-envelope {
  background-color: #f3f3f3;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: $tt-spacing-unit-small;
  left: $tt-spacing-unit-small;
  width: 100%;
  height: 100%;
}

.editor__pack-details{
  padding-top: 25px;
  text-align: center;
}

.editor__text-input-modal-control {
  max-height: 100%;
  width: 100%;
  resize: none;
  text-align: center;
  font-size: 28px;
  line-height: 1.3; // Fabric uses a 1.3 line height internally, so we try to match it as close as possible
  border: 0;
  outline: 0;
  caret-color: $tt-color-primary;
}

.editor__info-button-container {
  text-align: center;
  bottom: 0px;
  position: absolute;
  width: 100%;
  margin-left: -15px;
  .btn {
    border: 2px solid;
    font-size: 1em;
    text-transform: uppercase;
    transform: scale(0.65);
  }
}

.editor__signature-input-modal-canvas {
  background-color: #fff;
  width: 100%;
  height: 100px;
}

.editor__signature-color-options {
  margin-top: 3px;
  text-align: center;
  background-color: #f9f9f9;
}

.editor__product-size-option {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.editor__product-size-option {
  td {
    padding: $tt-spacing-unit-small;
  }
}

.editor__product-size-option--selected {
  .editor__product-size-option-title {
    color: $tt-color-primary;
  }
}

.editor__product-size-option__preview,
.editor__product-size-option__info {
  margin-right: $tt-spacing-unit-default;
}

.editor__product-size-option-title {
  font-weight: $tt-font-weight-light;
  transition: color 0.2s;
}

.editor__product-size-option-subtitle {
  font-size: $tt-font-size-small;
}

.editor__product-size-option__price {
  display: inline-block;
  margin-right: $tt-spacing-unit-extra-small;
}

.editor__edit-text-btn {
  .icon {
    top: 2px;
    left: 4px;
  }

  .icon,
  svg {
    width: 44px;
    height: 44px;
  }
}

.editor__quantity-picker {
  margin-bottom: $tt-spacing-unit-default;
  margin-right: $tt-spacing-unit-default;
}

.editor__quantity-picker-controls {
  .btn {
    padding: 0;
    margin: 0;

    &:first-child {
      margin-right: -1px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.editor__arrival-estimation {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;

  .date {
    flex: 1;
  }
}

.editor__arrival-estimation-divider {
  font-size: 36px;
  font-weight: $tt-font-weight-bold;
  padding: 0 $tt-spacing-unit-default;
  color: #909090;
}

// Text editor
.editor__text-editor {
  .modal {
    background-color: $tt-color-border;
  }
}

.editor__text-editor-content-container {
  height: 100%;
  width: 100%;
  padding: 3px;
  margin: 0 auto;
  //background-color: rgba(red, 0.1);
}

.editor__text-editor-content {
  resize: none;
  height: 100%;
  width: 100%;
  border: 0;
  overflow: hidden;
  overflow-y: auto;
  background-color: #fff;
  font-size: 1em;
  transition: background-color 0.2s;
  border-radius: 0;
  caret-color: $tt-color-primary;
}

.editor__text-editor-content--dark {
  background-color: #333;
}

.editor__text-editor-height-warning {
  position: absolute;
  top: 13px;
  left: $tt-spacing-unit-default;
  right: $tt-spacing-unit-default;
  padding: $tt-spacing-unit-small;
  background-color: #ff5a5fe8;
  color: $tt-color-text-light;
  z-index: 100;
  text-align: center;
}

.editor__text-editor-tool {
  background-color: $tt-color-border-light;
  height: $text-editor-tool-height;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editor__text-editor-tool--padded {
  padding: 0 $tt-spacing-unit-large;
}

.editor__font-color-preview {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid $tt-color-border;
}

.modal-overlay.editor__text-editor {
  z-index: -1;
  pointer-events: none;
}

.modal-overlay.editor__text-editor--open {
  z-index: 100;
  pointer-events: all;
}

.editor__pack-mode-container{
  padding-bottom: 10px;
  // position: absolute;
  // bottom: 0px;
  // margin-bottom: 10px;
  // right: 10px;
  // display: block;
}

.editor-modal__cropper-info{
  background: $tt-color-success;
  margin: 10px auto;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  p {
    color: white;
  }
}


//Canvas preview

// OPTIONS
////////////////////////////////////
// image url to be used
$imageURL: url(https://unsplash.it/560/280?image=1039);

// frame type ('wrap' 'white' 'black')
$type: "wrap";

// canvas (image) width
$canvasWidth: calc(25vh * 1.5);

// canvas (image) height
$canvasHeight: 25vh;

// depth of canvas frame
$canvasDepth: 10px;

// MIXINS
////////////////////////////////////
// mixin for psudeo elements
@mixin psudeo() {
  display: block;
  content: "";
  position: absolute;
}

// mixin for right frame
@mixin rightFrame() {
  transform: scaleX(-1) skew(0, 45deg);
  transform-origin: 0% 50%;
  margin-left: $canvasDepth;
  margin-top: -$canvasDepth;
  left: 100%;

  // if frame type = wrap,
  // include background image and apply shadow
  @if $type == "wrap" {
    @include backgroundImage();
    &:after {
      @include psudeo();
      position: absolute;
      width: $canvasDepth;
      height: $canvasHeight;
      background: rgba(#000, 0.38);
    }

    // if frame type = white
    // set background to light grey
  } @else if $type == "white" {
    background: #999;

    // if frame type = black
    // set background to dark grey
  } @else if $type == "black" {
    background: #111;
  }
}

// mixin for top frame styles
@mixin topFrame() {
  transform: scaleY(-1) skew(45deg, 0);
  transform-origin: 0% 50%;
  margin-left: $canvasDepth/2;
  top: -$canvasDepth;

  // if frame type = wrap,
  // include background image and apply shadow
  @if $type == "wrap" {
    @include backgroundImage();
    // shadow
    &:after {
      @include psudeo();
      position: absolute;
      width: $canvasWidth;
      height: $canvasDepth;
      background: rgba(#000, 0.25);
    }

    // if frame type = white
    // set background to light grey
  } @else if $type == "white" {
    background: #c5c5c5;

    // if frame type = black
    // set background to dark grey
  } @else if $type == "black" {
    background: #282828;
  }
}

// mixin for background-image settings
@mixin backgroundImage() {
  //background-image: $imageURL;
  //background-size: cover;
  //background-size: 120%;
  //background-position: top right;
  background-size: 120%;
  background-position: -26px -26px;
}

// STYLES
////////////////////////////////////
.canvas-preview {
  @include backgroundImage();
  margin: -($canvasDepth/2) auto auto;
  margin-top: 25px;
  position: relative;
  width: $canvasWidth;
  height: $canvasHeight;
  box-shadow: 5px 0px 25px 3px #000;
  // top frame
  .top {
    @include psudeo();
    @include topFrame();
    width: $canvasWidth;
    height: $canvasDepth;
  }
  // right frame
  .right {
    @include psudeo();
    @include rightFrame();
    width: $canvasDepth;
    height: $canvasHeight;
  }
}
