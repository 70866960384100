.photo-magazine-cover-band {
  text-align: center;
  pointer-events: none;
  position: absolute;
  bottom: 12.5%;
  left: 0;
  right: 0;
  height: 12.5%;
  background-color: #fff;
}

.photo-magazine-cover-band__text {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
