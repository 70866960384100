@import "../../styles/abstracts/variables";

$divider-color: rgba($tt-color-text-dark, 0.5);

.divider {
  width: 100%;
  margin: $tt-spacing-unit-large 0;
  position: relative;
}

.divider__line {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  height: 1px;
  background-color: $divider-color;
}

.divider__label {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: $tt-color-text-light;
  color: $divider-color;
  padding: 0 4px;
  text-transform: uppercase;
  font-size: $tt-font-size-small;
  z-index: 2;
}
