@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins/icon-color";

.rating-stars {
  @include iconColor($tt-color-primary);
}

.rating-stars--no-rating {
  opacity: 0.35;
}

.rating-stars--yellow {
  @include iconColor(#fdc930);
  .icon--small {
    width: 15px;
    height: 15px;
  }
}
