.react-datepicker__portal {
  background-color: rgba(144, 144, 144, 0.8);
}

.react-datepicker {
  border: none;
}

.react-datepicker__header {
  background-color: $tt-color-primary;
  border-bottom: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: $tt-color-primary;
}

.react-datepicker__day-name,
.react-datepicker__time-name {
  color: white;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  color: white;
  font-weight: 400;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #fff;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #fff;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: $tt-color-primary;
}
