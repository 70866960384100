@import "../../styles/abstracts/variables";

.editor__image-option {
  margin-right: $tt-spacing-unit-large;
  height: 50px;
  box-shadow: none;
  transition: box-shadow 0.2s;

  &:last-child {
    margin-right: 0;
  }
}

.editor__fill-option--first-after-fixed {
  margin-left: 48px;
}

.editor__image-option--is-active {
  box-shadow: 0 0 0 2px #fff, 0 0 4px 4px rgba($tt-color-primary, 0.4);
}
