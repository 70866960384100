@import "../../styles/abstracts/variables";

$block-border-color: $tt-color-border;

.block {
  width: 100%;
  outline: 0;
  border: 0;
  padding: 10px 20px;
  background-color: #fff;
  transition: opacity 0.2s;
  border-bottom: 1px solid $block-border-color;
  display: flex;
  align-items: center;
  min-height: 50px;
  text-decoration: none;

  &:first-child {
    border-top: 1px solid $block-border-color;
  }

  &:last-child {
    margin-bottom: 20px;
  }
}

.block--clickable {
  &:active {
    opacity: 0.6;
  }
}

.block--border-top {
  border-top: 1px solid $block-border-color;
}

.block__main-content {
  flex: 1;

  font-weight: $tt-font-weight-light;
  color: rgba($tt-color-text-dark, 0.8);

  p {
    font-weight: $tt-font-weight-light;
    color: rgba($tt-color-text-dark, 0.8);
  }

  a {
    text-decoration: none;
    color: inherit;
    display: block;
  }

  .active {
    color: $tt-color-primary;
  }
}

.block__arrow-container {
  padding-left: 10px;
}
