@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins/icon-color";

.shop__grid {
    background-color: #EFF1F3;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: $tt-spacing-unit-small;
    padding-top: $tt-spacing-unit-default;
  }
  
  .shop__design-selection-modal {
    .modal__page {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      flex-direction: row;
    }
  }
  
  .greeting-card-design {
    flex: 0 0 calc(50% - #{$tt-spacing-unit-default});
    margin-bottom: $tt-spacing-unit-large;
    padding: $tt-spacing-unit-small;
    text-align: center;
    background-color: #fff;
    font-family: $tt-font-stack-secondary;
    max-width: 230px;
  }
  
  .greeting-card-design__title{
    h4{
      font-weight: $tt-font-weight-light;
      margin-bottom: $tt-spacing-unit-default;
      line-height: 1.5em;
      color: rgba($tt-color-text-dark, 0.8);
    }
  }
  
  .greeting-card-design__preview {
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }
  
  .greeting-card-design__preview-portrait {
    width: 85%;
  }
  
  .greeting-card-design__colors {
    margin-top:10px;
    text-align: center;
  }
  
  .greeting-card-design__color {
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: $tt-spacing-unit-extra-small;
    border-radius: 50%;

    &:last-child {
      margin-right: 0;
    }
    &.with-border{
      border: 1px solid #ccc;
    }
  }
  
  .greeting-card-design__color__with-border{
    border: 1px solid #ccc;
  }
  
  .shop__design-preview-modal {
    background-color: transparent;
  
    .btn--tertiary {
      @include iconColor($tt-color-text-dark);
    }
  
    .modal {
      background-color: rgba(255, 255, 255, 0.85);
    }
  
    .shop__design-preview-modal-color {
      width: 40px;
      height: 40px;
      display: inline-block;
      margin-right: $tt-spacing-unit-default;
      border: 1px solid #fff;
      transition: all 0.2s ease;
  
      &:last-child {
        margin-right: 0;
      }
    }
  
    .shop__design-preview-modal-color--active {
      box-shadow: 1px 0px 15px 4px rgba(#fff, 0.5);
      transform: scale(1.05);
    }
  }
  
  .shop__hero {
    img {
      width: 100%;
      display: block;
    }
  }
  
  .shop__hero-banner {
    background-color: $tt-color-primary;
    padding: 4px;
  
    .shop__hero-banner-text{
      color: $tt-color-text-light;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
    }
  }
  
  .shop__marketing-text {
    border-top: 1px solid $tt-color-border;
    background-color: #fff;
    padding: $tt-spacing-unit-default;
    h1{
      font-weight: $tt-font-weight-light;
      margin-bottom: $tt-spacing-unit-default;
      line-height: 1.5em;
      color: rgba($tt-color-text-dark, 0.8);
      text-align: center;
      font-size: 1.3em;
    }
    h2{
      font-weight: $tt-font-weight-light;
      margin-bottom: $tt-spacing-unit-default;
      line-height: 1.5em;
      color: rgba($tt-color-text-dark, 0.8);
      text-align: center;
      font-size: 1.2em;
    }
  }
  
  .shop__ctas{
    padding: $tt-spacing-unit-default;
    width: 68%;
    margin: 0 auto;
    max-width: 330px;
    min-width: 253px;
  }
  
  .shop__prepay-review-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 250px;
    padding: 20px;
  
    .shop__prepay-container{
      flex: 2 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: $tt-color-primary; //#594b64
      height: 100%;
      .shop__prepay-content{
        position: relative;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h3{
          text-align: center;
          font-weight: 500;
          padding: 10px;
          line-height: 1.5em;
          margin-bottom: 20px;
        }
        .shop__prepay-button{
          text-align: center;
          font-weight: 500;
          padding: 10px;
          line-height: 1.5em;
          color: white;
          border: 1px solid white;
          width: 80%;
          text-align: center;
          margin: 0 auto;
          border-radius: 2px;
          text-transform: uppercase;
          font-size: 0.8rem;
          cursor: pointer;
          text-decoration: none;
          z-index: 1;
        }
        div{
          position: absolute;
          top: -10px;
        }
      }
    }
    .shop__review-container{
      flex: 1 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid #9a9a9a;
      height: 100%;
      text-align:center;
      padding: 0px 15px;
      p{
        margin: 3px;
      }
    }
  }
  
  .shop__start-card-ctas{
    .btn{
      border: 1px solid #5C5C5C;
      border-radius: 2px;
      font-size: 0.68rem;
      color: #5C5C5C;
      max-width: 165px;
      margin: 0 auto;
      margin-bottom: 10px;
      text-transform: uppercase;
      background: white;
      &:hover,
      &:active {
        background-color: lighten(#5C5C5C, 5%);
        border-color: lighten(#5C5C5C, 5%);
        color: white;
      }
    }
    .divider{
      width: 34%;
      margin: 0 auto;
      position: relative;
      margin-top: 25px;
      margin-bottom: 35px;
    }
  }
  
  .shop__popular-cards-container{
    h2{
      font-weight: 300;
      margin-bottom: 16px;
      line-height: 1.5em;
      color: rgba(51, 51, 51, 0.8);
      text-align: center;
      font-size: 1.2em;
    }
    .shop__popular-cards{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .greeting-card-design{
        flex: 1 0 50%;
        padding: 15px;
        margin-bottom: 10px;
      }
    }
  }
  
  .shop__download{
    padding: 10px;
    text-align: center;
  }
  
  .shop__social-icons{
    width: 100%;
    margin: 15px auto;
    text-align: center;
    .social-icon{
      margin: 0px 10px;
      padding: 5px;
      width: 24px;
      height: 24px;
    }
  }
  
  .shop__footer{
    border-top: 1px solid #ccc;
    text-align: center;
    padding: 10px;
    .shop__footer-text{
      color: $tt-color-text-dark;
      font-size: 12px;
      font-weight: 400;
      .shop__footer--link{
        text-decoration: none;
        color: $tt-color-text-dark;
        &:active {
          color: $tt-color-primary;
        }
      }
    }
  }