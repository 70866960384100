@import "../../styles/abstracts/variables";

$icon-small-dimensions: 18px;
$icon-regular-dimensions: 24px;
$icon-large-dimensions: 28px;

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &,
  svg {
    width: $icon-regular-dimensions;
    height: $icon-regular-dimensions;
  }
}

// Size variations
.icon--small {
  &,
  svg {
    width: $icon-small-dimensions;
    height: $icon-small-dimensions;
  }
}

.icon--large {
  &,
  svg {
    width: $icon-large-dimensions;
    height: $icon-large-dimensions;
  }
}

.icon--full-width {
  margin: 0 auto;
  &,
  svg {
    width: 70%;
    height: 70%;
    padding: 5%;
    path {
      fill: $tt-color-text-dark;
      opacity: 0.2;
    }
  }
}
