.gsa {
  .uploadcare--dialog {
    height: calc(100% - 130px);
  }
}

.uploadcare--dialog {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  transition: none;
  z-index: 200;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  &:not(.uploadcare--single-file) {
    .uploadcare--preview {
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-name: slideInRight;
      animation-name: slideInRight;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
  }
}

.uploadcare--file-sources,
.uploadcare--menu__items,
.uploadcare--menu__toggle,
.uploadcare--file__name,
.uploadcare--file__size,
.uploadcare--preview__done,
.uploadcare--file__remove,
.uploadcare--preview__back {
  display: none;
}

@media (min-width: 1210px){
  .uploadcare--dialog__panel .uploadcare--menu, .uploadcare--dialog__panel .uploadcare--menu__item {
    display: none;
  }
  .uploadcare--button_primary{
    min-width: auto !important;
    width: 200px;
  }
}

@media (min-width: 760px){
  .uploadcare--button_primary {
    min-width: auto !important;
    width: 200px;
  }
  .uploadcare--menu {
    width: 0px;
  }
}

.uploadcare--menu__items {
  //display: block !important; //hide canvas
}

.uploadcare--progress_type_canvas {
  color: $tt-color-primary;
}

.uploadcare--menu__item_current{
  color: transparent;
}

.uploadcare--file_status_uploading .uploadcare--file__preview {
  background-color: #d7d7d7 !important;
  border-radius: 2px;
  margin-top: 10px;
}

.uploadcare--file__size {
  font-size: 12.5px;
  color: #a4a2a1;
  display: block !important;
  text-align: left;
}

.uploadcare--single-file {
  .uploadcare--preview__done {
    display: block;
    opacity: 0;
    pointer-events: none;
  }
}

.uploadcare--file_status_uploading {
  .uploadcare--file__preview,
  .uploadcare--progressbar__value {
    background-color: $tt-color-primary;
  }
}

.uploadcare--file_status_uploading {
  .uploadcare--file__preview {
    background-color: #444;
    border-radius: 2px;
  }
}

.uploadcare--tab_name_file {
  .uploadcare--panel__footer {
    display: none;
  }

  & ~ .uploadcare--panel__footer {
    display: none;
  }
}

.uploadcare--dialog {
  font-family: $tt-font-stack-primary;
}

.uploadcare--footer__additions{
  display:none;
}

.uploadcare--single-file .uploadcare--preview__done{
  min-width: 100%;
}

@media (min-width: 760px){
  .uploadcare--single-file .uploadcare--preview__done{
    min-width: 350px;
    max-width: 350px;
    transform: translateX(-30px);
  }
}

.uploadcare--tab__header{
  text-align:center;
  margin: 0 auto;
}

#upload-wait{
  min-width: 100%;
}

.uploadcare--text_size_large {
  font-size: 15px;
  font-weight: normal;
  //text-transform: uppercase;
  letter-spacing: 0.5px;
  color: $tt-color-text-dark;
  margin-bottom: 10px;
  font-family: $tt-font-stack-primary;
}

.uploadcare--button_muted {
  .uploadcare--icon {
    color: #444;
  }
}

.uploadcare--file_status_error .uploadcare--file__progressbar,
.uploadcare--file_status_error .uploadcare--file__size,
.uploadcare--file_status_uploaded .uploadcare--file__progressbar {
  visibility: hidden;
  display: inline-block !important;
}

.uploadcare--dialog__close {
  left: 0;
  right: initial;
}

.uploadcare--button {
  border-radius: 3px;
  color: $tt-color-primary;

  &:focus,
  &:hover,
  &:active {
    
    //color: darken($tt-color-primary, 15%);
  }
}

.uploadcare--panel__footer {
  background: transparent;
}

.uploadcare--button:focus, .uploadcare--button:hover, .uploadcare--button:active {
  outline: none !important;
}

.uploadcare--button_primary:focus,
.uploadcare--button_primary:hover,
.uploadcare--button_primary:active {
  // color: $tt-color-primary !important;
  // border-color: $tt-color-primary !important;
  // background-color: #fff !important;
  background-color: $tt-color-primary !important;
  font-weight: 500 !important;
}

.list-icons-item._selected, .list-tiles-item._selected {
  opacity: .7;
  border: 3px solid $tt-color-primary;
  border-style: inset;
  margin: 1px;
  border-radius: 0px;
}

.list-icons-item {
  margin: 4px;
}

.uploadcare--dragging__show {
  display: inline-block !important;
  font-size: 1rem;
  font-family: "Poppins";
}

.uploadcare--panel__show-files{
  display:none;
}

.uploadcare--button_primary {
  font-family: "Poppins";
  //position: absolute;
  //bottom: 10%;
  margin: 0 auto;
  //width: 90%;
  //left: 5%;
  min-width: 100%;
  font-size: 0.875rem;
  font-weight: 500;

  border-color: $tt-color-primary;
  background: $tt-color-primary;
  color: #fff;

  &:focus,
  &:hover,
  &:active {
    color: #fff;
    border-color: darken($tt-color-primary, 15%);
    background-color: darken($tt-color-primary, 15%);
  }
}

.uploadcare--file__preview {
  border: 0;
  border-radius: 2px;
  margin-top: 10px;
  width: 80px !important;
  height: 80px !important;
}

.uploadcare--file {
  pointer-events: none;
  border-bottom: 1px solid #ececec;

  &:first-child {
    border-top: 1px solid #ececec;
  }

  &:hover {
    background-color: transparent;
  }
}

.uploadcare--file__progressbar {
  margin: 0 25px;
  width: 60%;
  height: 5px;
}

.uploadcare--file__icon {
  box-shadow: 2px 2px 8px rgba(#000, 0.3);
  width: 100%;
}

.uploadcare--panel_multiple
  .uploadcare--tab_name_preview
  .uploadcare--tab__footer:before {
  display: none;
}

.uploadcare--powered-by {
  display: none;
}

.uploadcare--preview__file-name {
  display: none !important;
}

.meter {
  height: 25px;
  position: relative;
  display: block;
  margin: 10px 0;
  width: 80%;
  height: 3px;
  display: inline-block;
}
.meter > span {
  position: relative;
  display: block;
  width: 0;
  height: 100%;
  border-radius: 8px;
  background-color: $tt-color-primary;
  transition: width 0.25s;
}
.meter > span:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-size: 50px 50px;
  -webkit-animation: meter-move 2s linear infinite;
  animation: meter-move 2s linear infinite;
  border-radius: 8px;
}

.uploadcare--desktop-multiphoto-tip{
  width: 400px;
  margin-top: 25px;
  border: 1px solid #e9e9e9;
  padding: 15px 34px;
  background: #fff;
  color: white;
  box-shadow: 1px 2px 8px 0px #dedede;
  border-radius: 10px;

  .cmd-key{
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    background: #606060;
    color: white;
  }

  p{
    //color: #49bda1;
    //font-weight:600;
  }
}

.psFavorite-files-image:hover {
  color: #f75e678a;
}
.psFavorite-files-image {
  width: 140px;
  height: 140px;
  margin: 10px;
  //padding: 5px;
  color: rgba(0,0,0,.4);
  //box-shadow: 0 6px 20px -3px;
  box-shadow: 4px 2px 18px -3px;
  cursor: pointer;
  border-radius: 4px;
  &.added{
    // color: #f75e678a;
    // border: 3px solid #F75E67;
    color: #63C0AC;
    border: 2px solid #63C0AC;
    filter: opacity(0.5);
  }
}
@media (max-width: 760px){
  .psFavorite-files-image {
    width: 100px;
    height: 100px;
  }
}

#psFavoritesTab {
  overflow-y: scroll;
  height: calc(100% - 10px);
  .heading{
    font-size: 20px;
    margin-top: 15px;
  }
}
.uploadcare-dialog-tab.uploadcare-dialog-tab-favorites::before {
  display: none;
}
.uploadcare--tab_name_favorites{
  overflow: scroll;
}

@-webkit-keyframes meter-move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
@keyframes meter-move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
