@import "../../styles/abstracts/variables";

.editor__font-option {
  display: inline-block;
  height: 32px;
  padding: 0 $tt-spacing-unit-default;
  line-height: 16px;
  background-color: transparent;
  outline: 0;
  border: 0;
  font-size: 16px;
  color: $tt-color-text-dark;
}

.editor__font-option--active {
  color: $tt-color-primary;
}
