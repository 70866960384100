@import "../../styles/abstracts/variables";

$global-progress-bar-height: 4px;
$global-progress-bar-track-color: $tt-color-primary;

.global-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: $global-progress-bar-height;
  pointer-events: none;
  z-index: 500;
}

.global-progress-bar__track {
  background-color: $global-progress-bar-track-color;
  height: 100%;
  transition: width 0.2s;
}
