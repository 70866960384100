@import "../../styles/abstracts/variables";

$html-renderer-layer-z-indexes: (
  text: 5,
  placeholder: 5,
);

.html-renderer-container {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
}

.html-renderer-container--no-border {
  box-shadow: none;
}

.html-renderer {
  position: relative;
  user-select: none;
  width: 100%;
}

.html-renderer--non-interactive {
  .html-renderer-rect {
    pointer-events: none;
  }
}

.html-renderer-rect {
  position: absolute;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.html-renderer-photo-layer-effect {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("/app/images/canvas-overlay.png");
  background-size: cover;
  opacity: 0.4;
}

.html-renderer-photo-layer__region {
  position: absolute;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.crop-alert {
  .icon--crop-rotate {
    vertical-align: middle;
  }
}

.html-renderer-photo-layer__region--has-image {
  display: block;
}

.html-renderer-photo-layer__region__uncropped-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.html-renderer-photo-layer__region__cropped-image {
  display: block;
  min-width: 0 !important;
  min-height: 0 !important;
  max-width: none !important;
  max-height: none !important;
}

.html-renderer-photo-layer__region__placeholder-icon {
  width: 48px;
  height: 48px;
}

.html-renderer-photo-layer__region__image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
}

.html-renderer-photo-layer__border {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-size: 40px; // TODO
  pointer-events: none;

  &.lower-canvas {
    left: -1px !important;
    top: -1px !important;
  }
}

.html-renderer-placeholder-layer {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $tt-color-primary;
  color: $tt-color-primary;
  font-size: 1em;
  padding: $tt-spacing-unit-default;

  p {
    color: inherit;
  }
}

.html-renderer-mask {
  pointer-events: none;
}

.html-renderer-mask__line,
.html-renderer-mask__band,
.html-renderer-mask__corner {
  position: absolute;
}

.html-renderer-mask__corner {
  background-color: #fff;
}

.html-renderer-mask__band--horizontal {
  left: 0;
  right: -2px;
  background-color: #fff;
}

.html-renderer-mask__band--vertical {
  top: 0;
  bottom: 0;
  background-color: #fff;
}

.html-renderer-mask__line--horizontal {
  left: 0;
  right: 0;
  height: 1px;
  border-top: 2px dashed #fff;
}

.html-renderer-mask__line--vertical {
  top: 0;
  bottom: 0;
  width: 1px;
  border-left: 2px dashed #fff;
}

.html-renderer-mask__line--vertical {
  &.placeholder {
    border-color: #d2d2d2;
  }
}

.html-renderer-mask__line--horizontal {
  &.placeholder {
    border-color: #d2d2d2;
  }
}

.html-renderer-graphic-layer,
.html-renderer-indicia-layer {
  background-size: cover;
  pointer-events: none;
}

.html-renderer-indicia-layer {
  pointer-events: none;
}

.html-renderer-text-layer {
  touch-action: none;
  z-index: map-get($html-renderer-layer-z-indexes, text);

  &:not(.html-renderer-text-layer--is-locked) {
    .html-renderer-text-layer__container {
      padding: 1em; // We're using em-units here to make sure the padding is consistent across all render sizes
    }
  }
}

.html-renderer-text-layer__container {
  position: relative;
}

.html-renderer-text-layer--is-selected {
  .html-renderer-text-layer__container {
    border: 1px solid $tt-color-primary;
    margin: -1px;
    transition: background-color 0.5s ease;
  }

  .html-renderer-text-layer__control {
    display: block;
  }
}

.html-renderer-text-layer--is-moving {
  .html-renderer-text-layer__container {
    background: #dd345461;
  }
}

.html-renderer-text-layer--is-locked {
  .html-renderer-text-layer__container {
    height: 100%;
  }
}

.html-renderer-text-layer__control {
  width: 24px;
  height: 24px;
  position: absolute;
  display: none;
}

.html-renderer-text-layer__control--rotate {
  top: -12px;
  right: -12px;
}

.html-renderer-text-layer__control--resize {
  bottom: -12px;
  right: -12px;
}

.html-renderer-text-layer__control--delete {
  top: -12px;
  left: -12px;
}

.html-renderer-text-layer__control--edit {
  bottom: -12px;
  left: -12px;
}

.html-renderer-text-layer__content {
  width: 100%;
  overflow: hidden;
  height: 100%;
  resize: none;
  border: 0;
  background-color: transparent;
  user-select: none;
  pointer-events: none;
  white-space: pre-line;
  word-wrap: break-word;

  //center text vertically
  // display: flex;
  // place-content: center;
  // flex-direction: column;
}

.html-renderer-text-layer__rotation-handle {
  width: 10px;
  height: 10px;
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: skyblue;
  border: 2px solid #fff;
}

.html-renderer-signature-layer {
  display: flex;
}

.html-renderer-signature-layer__avatar {
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 2px;
  flex: 0 auto;
  border-radius: 50%;
}

.html-renderer-signature-layer__drawing {
  flex: 1;
  background-size: cover;
}

.html-renderer-debug-layer {
  pointer-events: none;
}

.html-renderer-debug-layer__layer-id {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  color: #fff;
  font-size: 12px;
  padding: 3px;
  opacity: 0.3;
}
