@import "../../styles/abstracts/variables";

.main-content {
  flex: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  -webkit-overflow-scrolling: touch;
  // Aternative to fix lartge device scrolling issue
  //padding-left: calc((100vw - 750px) / 2);
  //padding-right: calc((100vw - 750px) / 2);
}

.main-content--scrollable {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.main-content--padded {
  padding: $tt-spacing-unit-default;
}

.main-content--centered-vertically {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-content__content {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
