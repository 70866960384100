@import "../../styles/abstracts/variables";

.form {
  width: 100%;

  fieldset {
    border: 0;

    legend {
      display: none;
    }
  }

  .form-group {
    margin-bottom: $tt-spacing-unit-default;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .form-control {
    width: 100%;
    display: block;
    border: 1px solid #e8e8e8;
    padding: 10px;
    font-size: 18px;
    font-family: $tt-font-stack-primary;
    border-radius: 3px;
    height: 44px;
    background-color: #fff;
    -webkit-appearance: none;
    &[disabled],
    &[readonly] {
      color: rgba($tt-color-text-dark, 0.7);
    }
  }

  textarea.form-control {
    height: auto;
  }

  .form-group--has-floating-icon {
    .form-control {
      padding-right: 44px + $tt-spacing-unit-default;
    }

    .btn {
      position: absolute;
      height: 32px;
      top: 0;
      right: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .form-control-container {
    position: relative;
  }

  .control-label {
    font-size: 14px;
    color: rgba($tt-color-text-dark, 0.7);
    margin-bottom: 2px;
    display: inline-block;
  }

  .help-block {
    font-size: $tt-font-size-small;
    margin-top: $tt-spacing-unit-extra-small;
    color: rgba($tt-color-text-dark, 0.5);
  }

  .checkbox {
    input[type="checkbox"] {
      margin-right: $tt-spacing-unit-small;
    }
  }

  .error-detail {
    background-color: rgba(red, 0.1);
    border: 1px solid rgba(red, 0.2);
    border-radius: 3px;
    padding: $tt-spacing-unit-default;
    list-style-type: none;
  }
}

.pretty {
  .state label:after,
  .state label:before {
    transition: all 0.2s;
  }
}

.pretty input:checked ~ .state label:after,
.pretty.p-toggle .state label:after {
  background-color: $tt-color-primary !important;
  //top: -1px;
}

.pretty.p-default input[type="radio"]:checked ~ .state label:after {
  background-color: $tt-color-primary !important;
}

.pretty.p-default input[type="radio"]:checked ~ .state label:before {
  border-color: $tt-color-primary !important;
}

.radio {
  label {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}

.radio__custom-control {
  margin-right: 5px;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  background: #fff;
  border: 2px solid #ccc;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: calc(50% - 4px);
    left: calc(50% - 4px);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color .3s;
  }
}

/* Hover and focus states */
.radio:focus input ~ .radio__custom-control,
.radio:active input ~ .radio__custom-control,
.radio input:focus ~ .radio__custom-control {
  border-color: darken(#ccc, 10%);
}

/* Checked state */
.radio input:checked ~ .radio__custom-control {
  &:after {
    background-color: $tt-color-primary;
  }
}

/* Hover state whilst checked */
.radio:hover input:not([disabled]):checked ~ .radio__custom-control,
.control input:checked:focus ~ .radio__custom-control {
  //box-shadow: inset 0 0 0 2px $tt-color-primary;
}
