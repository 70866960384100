@import "../../styles/abstracts/variables";

.address-book__character {
  font-size: $tt-font-size-large;
  font-weight: bold;
  color: $tt-color-primary;
  margin-left: $tt-spacing-unit-default;
}

.selected-address {
  line-height: 1.4;
  font-size: 13px;
  white-space: pre;
  color: black;
  border: 1px solid #e5e5e5;
  padding: 10px;
  width: 100%;
  box-shadow: 2px 2px 3px 2px #e1e1e1;
  border-radius: 4px;
}
