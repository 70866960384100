@import "../../styles/abstracts/variables";

.promotion-code-input__input-wrapper {
  display: flex;

  .form-control {
    flex: 1;
    font-size: 1em;
    &:focus {
      border: 1px solid $tt-color-primary;
      -webkit-appearance: none;
      outline: none;
      outline-width: 0;
    }
  }
}

.promotion-code-text {
  text-align: center;
  font-weight: 500;
  color: #49bda1;
  cursor: pointer;
  font-size: 12px;
  &:hover {
    text-decoration: underline;
  }
}

.promotion-code-dismiss-text {
  text-align: right;
  float: right;
  font-weight: 500;
  color: #49bda1;
  cursor: pointer;
  margin-bottom: 0px;
  font-size: 12px;
}

.item-preview {
  position: relative;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
  &.shadowed {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }
}

.upsell--magazine-container{
  position: relative;
  .upsell--intro-text{
    text-align: center;
    font-size: 12px;
    margin-bottom: 25px;
  }
  .upsell--footer-text{
    text-align: center;
    font-size: 12px;
    margin-top: 25px;
  }

  .upsell--promo-code{
    border: 1px solid #e1e1e1;
    padding: 5px 13px;
    color: #ff5a5f;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: 1px 1px 13px 2px #d7d7d7;
  }

  .upsell--magazine-cover{
    .pagecurl-overlay{
      pointer-events: none;
      position: absolute;
      bottom: 0;
      right: 0;
      background-image: url("/app/images/pagecurl.png");
      background-size: cover;
      width: 20%;
      height: 20%;
    }


    .pagecurl {
      width:17%;
      height:17%;
      position: absolute;
      bottom:0;
      right:0;
      background : 
        linear-gradient(
          135deg, 
          #fff, 
          #f3f3f3 45%, 
          #ddd 50%, 
          #aaa 50%, 
          #bbb 56%, 
          #ccc 62%, 
          #f3f3f3 80%,
          #fff 100%
        );
      box-shadow : 0 0 10px rgba(0, 0, 0, .5);
      transition: all .5s ease;
    }
    .pagecurl:before,
    .pagecurl:after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 12.5%;
      bottom: 5.8%;
      width: 70%;
      max-width: 300px;
      max-height: 100px;
      height: 55%;
      box-shadow: 0 12px 15px rgba(0, 0, 0, .3);
      transform: skew(-10deg) rotate(-6deg);
    }
    .pagecurl:after {
      left: auto;
      right: 5.8%;
      bottom: auto;
      top: 14.16%;
      transform: skew(-15deg) rotate(-84deg);
    }
    
    .pagecurl:hover {
      width: 22%;
      height: 22%;
    }
    .pagecurl:hover:before,
    .pagecurl:hover:after {
      box-shadow: 0 24px 30px rgba(0, 0, 0, .3);
    }
    position: relative;
    width: 250px;
    height: 250px;
    background-size: cover;
    background-position: center;
    margin: 0 auto;
    box-shadow: 3px 2px 9px 3px #d1d1d1;
    .upsell--magazine-band{
      position: absolute;
      bottom: 12.5%;
      background: white;
      width: 100%;
      text-align: center;
      height: 12.5%;
      font-size: 60%;
      padding: 4px;
      font-weight: 600;
      left: -1px;
    }
  }
  
}