@import "../../styles/abstracts/variables";

.email-capture-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(#000, 0.8);

  form {
    input[type="email"] {
      width: 100%;
      display: block;
      border: 1px solid #e8e8e8;
      padding: 10px;
      font-size: 18px;
      font-family: $tt-font-stack-primary;
      border-radius: 3px;
      height: 44px;
      background-color: #fff;
      -webkit-appearance: none;
      margin-bottom: $tt-spacing-unit-default;
    }

    button[type="submit"] {
      display: block;
      width: 100%;
      text-align: center;
      min-height: 44px;
      max-height: 44px;
      line-height: 44px - 2;
      padding: 0 $tt-spacing-unit-default;
      margin-right: $tt-spacing-unit-small;
      margin-bottom: $tt-spacing-unit-default * 1.25;
      border: 1px solid transparent;
      outline: 0;
      border-radius: 2px;
      font-family: $tt-font-stack-primary;
      font-size: $tt-font-size-regular;
      font-weight: $tt-font-weight-bold;
      text-decoration: none;
      transition: color 0.2s, background-color 0.2s, border-color 0.2s,
        opacity 0.2s;
      background-color: $tt-color-primary;
      color: $tt-color-text-light;
    }
  }

  .modal {
    width: 90vh;
    height: auto;
    max-width: 350px;
  }

  .modal__content {
    text-align: center;
  }
}

.email-capture-popup__title {
  font-size: $tt-font-size-extra-large;
  margin-bottom: $tt-spacing-unit-default;
}

.email-capture-popup__text {
  color: rgba(#000, 0.7);
}
