.swal2-modal .swal2-title {
  font-weight: 300;
}

.swal2-modal .swal2-cancel {
  border: 0;
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  font-weight: 300;
  margin: 15px 5px 0;
  padding: 10px 32px;
  border-color: rgb(170, 170, 170);
  color: #464646;
  background-color: transparent !important;
  border: 1px solid rgb(170, 170, 170);
}

.swal2-modal .swal2-confirm {
  border: 0;
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  font-weight: 300;
  margin: 15px 5px 0;
  padding: 10px 32px;
  color: #fff;
  background-color: #ff5a5f !important;
}
