@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins/mq";

.sidebar {
  min-width: $tt-sidebar-width;
  max-width: $tt-sidebar-width;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  transform: translateX(-100%);
  transition: transform 0.2s;
  background-color: #fff;
  border-right: 1px solid $tt-color-border;
  border-left: 1px solid $tt-color-border;

  @include mq($from: desktop) {
    //position: relative;
    //transform: none;
  }
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
    -webkit-border-radius: 6px;
  }
}

.sidebar__item {
  display: block;
  width: 100%;
  padding: $tt-spacing-unit-default;
  border: 0;
  border-bottom: 1px solid $tt-color-border;
  background-color: transparent;
  font-size: $tt-font-size-large;
  text-align: left;
}

.sidebar__item--navlink {
  padding: 0;

  a {
    text-decoration: none;
    color: inherit;
    display: block;
    padding: $tt-spacing-unit-default;
  }

  .active {
    color: $tt-color-primary;
  }
}

.sidebar__item--version {
  padding: 10px;
  font-size: $tt-font-size-small;
}

$tt-badge-dimensions: 25px;

.sidebar__item--message-count {
  pointer-events: none;
  width: $tt-badge-dimensions;
  height: $tt-badge-dimensions;
  line-height: $tt-badge-dimensions;
  text-align: center;
  border-radius: 50%;
  background-color: $tt-color-primary;
  color: $tt-color-text-light;
  font-size: 0.8em;
  display: inline-block;
  margin-left: 10px;
  padding: 0px;
}

.currency-select {
}
