@import "../../styles/abstracts/variables";

.filter-preview {
  display: inline-block;
  text-align: center;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  canvas {
    pointer-events: none;
  }
}

.filter-preview__name {
  margin-top: 5px;
  font-size: $tt-font-size-small;
}
