/**
This file is meant as a temporary holding area until we find a better way to put the various bits of code in here.
 */

.autocomplete {
  overflow: hidden;
  overflow-y: auto;
  max-height: 30vh;
  margin-left: 0px;
  background-color: #f5f5f582;
  border: 1px solid #e6e6e6;
  box-shadow: 4px 4px 9px 3px #cccccc61;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  transition: all 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.autocomplete__item {
  padding: $tt-spacing-unit-default;
  border-bottom: 1px solid $tt-color-border;
  &:hover{
    color: $tt-color-primary;
  }

  &:last-child {
    border-bottom: 0;
  }
}

table {
  width: 100%;
}

.app-value-frame {
  width: 100%;
  height: 99%; // TODO: figure out why 100% causes the app header to get nudged upwards
  border: 0;
}

.StripeElement:not(.form-control) {
  margin-bottom: $tt-spacing-unit-small;
}

.auth-modal {
  z-index: 100 !important;
}

html.renderer-mode {
  &,
  body {
    height: auto;
    overflow: auto;
    background-color: transparent !important; //overrides antds white background
  }

  .html-renderer-container {
    box-shadow: none;
  }
}

.pr-default {
  padding-right: $tt-spacing-unit-default;
}

.muted {
  opacity: 0.6;
}

.date {
  border-radius: 5px;
  border: 1px solid #ccc;
  text-align: center;
  box-shadow: 1px 1px 7px #dcdcdc;
}

.date__month {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 5px 0;
  background-color: $tt-color-primary;
  color: $tt-color-text-light;
  font-size: 24px;
  font-weight: 300;
}

.date__day {
  margin-top: 8px;
  font-size: 60px;
  font-weight: 300;
}

.date__day-of-week {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

#cropper-preview {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
  opacity: 0;
  overflow: hidden;
}
