@import "../../styles/abstracts/variables";

.threedimensionalviewer {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  height: 350px;
  flex: 1;

  .loader {
    position: absolute;
    height: 50%;
    width: 100%;
  }

  canvas {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    position: absolute;
    &.hidden {
      opacity: 0;
      //visibility: hidden;
    }
    &.shown {
      //visibility: visible;
      opacity: 1;
    }
  }
}

.threedimensionalviewer__waiting-text {
  position: absolute;
  top: 40%;
  width: 100%;
  font-size: 15px;
  background: #ffffff;
}
