@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins/icon-color";

.photo-magazine-prebag__page {
  padding-bottom: 2rem;
  display: flex;
  align-items: center;

  .html-renderer-container {
    margin: 0 !important;
  }
}

.photo-magazine-prebag__page-indicator {
  display: flex;
  align-items: center;
  margin: 0 -$tt-spacing-unit-small;
  margin-bottom: $tt-spacing-unit-small;
  padding: $tt-spacing-unit-small;
  background-color: #043253;

  p {
    flex: 1;
    padding-right: $tt-spacing-unit-default;
    margin-bottom: 0;
    color: #fff;
    font-size: $tt-font-size-small;
    text-align: center;
    font-weight: 500;
  }

  .btn {
    background-color: #fff;
    color: #043253;
  }

  .btn__label {
    font-size: $tt-font-size-small;
  }

  &.complete {
    background-color: $tt-color-success;
  }
}

.photo-magazine-prebag__drag-handle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  opacity: 0.5;
  transition: all 0.2s;
}

.photo-magazine-prebag__page-number {
  font-size: 0.6rem;
  width: 2.5rem;
}

.photo-magazine-prebag__actions {
  flex: 1;
  text-align: right;

  .photo-magazine-prebag__delete-button {
    display: inline-flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    margin: 0;
    border-radius: 50%;
    background: transparent;
    border: 1px solid $tt-color-primary;
    @include iconColor($tt-color-primary);

    svg {
      width: 1rem;
      height: 1rem;
    }

    .btn__icon {
      height: 1.5rem;

      .icon {
        top: 0;
      }
    }
  }
}

.photo-magazine-prebag__help-text {
  font-size: 11px;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #f2f2f2;
  .icon {
    position: relative;
    margin-right: 4px;
    svg {
      top: 5px;
      position: absolute;
      path {
        fill: rgba(129, 129, 129, 0.8);
      }
    }
  }
}
