@import "../../styles/abstracts/variables";

.fullscreen-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(#fff, 0.8);
  pointer-events: none;
  opacity: 0;
  transition: all 0.2s;
  transform: scale(1.5);
  z-index: 100;
}

.fullscreen-loader--is-visible {
  pointer-events: all;
  opacity: 1;
  transform: scale(1);
}

.fullscreen-loader__message {
  text-align: center;
  color: $tt-color-primary;
  position: absolute;
  bottom: 38%;
  display: inline-block;
  margin-top: 20px;
  width: 80%;
  left: 10%;
  margin: 0 auto;
  font-size: 1em;
}

.fullscreen-loader__spinner {
}
