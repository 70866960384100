@import "../../styles/abstracts/variables";

.editor__collage-option {
  outline: 0;
  border: 0;
  margin-right: 15px;
  background: #ffffff;

  &:last-child {
    margin-right: 0;
  }

  svg {
    rect {
      fill: #989898;
      stroke: #fff;
      stroke-width: 2px;
      transition: fill 0.2s;
    }
  }
}

.editor__collage-option:hover {
  svg {
    rect {
      fill: #999;
    }
  }
}

.editor__collage-option--active {
  svg {
    rect {
      fill: $tt-color-primary !important;
    }
  }
}
